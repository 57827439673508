import { Type } from 'class-transformer'
import { LinkedParameter } from '/@/shared/entities/LinkedParameter'
import { NA } from '/@/shared/constants'
import { uuid } from '/@/shared/utils'
import { Link } from '../importers/interfaces'
import Typeable from './shared/Typeable'
import { TypeSignature } from './enums/TypeSignature'

interface MappingData {
  name: string
  group: string
  offset: string
  bitLength: number
  type: TypeSignature
  linkedParameters?: (LinkedParameter | Link)[] | undefined
}

export class Mapping extends Typeable {
  public readonly uuid = uuid()

  public group: string = ''
  public name: string = NA
  public offset: string = NA
  public bitLength: number = 1
  public type = TypeSignature.NOT_SET

  @Type(() => LinkedParameter)
  public readonly linkedParameters: LinkedParameter[] = []

  constructor({ name, group, offset, bitLength, type, linkedParameters }: MappingData) {
    super(...arguments)

    this.name = name
    this.group = group
    this.offset = offset
    this.bitLength = bitLength
    this.type = type

    if (linkedParameters) {
      this.linkedParameters = linkedParameters.map(
        (data) =>
          new LinkedParameter(data.data, data.channel, data.sim, data.gain, data.divide, data.gainOffset, data.invert),
      )
    }
  }
}
