export function parseIndex(value: any, key: string) {
  if (key === 'index') {
    value = formatIndex(value)
  }

  return value
}

function formatIndex(index: string | number | undefined) {
  if (!isNaN(parseInt(String(index)))) {
    index = Number(index)
  }

  if (typeof index === 'string') {
    return index.substr(2)
  }

  if (index === undefined) {
    index = Number(0).toString(16)
  }

  if (typeof index === 'number') {
    index = index.toString(16)
  }

  return `${index.toLowerCase().padStart(4, '0')}`
}
