import { DC } from '../importers/interfaces'
/**
 * Distributed clock
 */
export class DistributedClock {
  public enabled: boolean = false
  public assignActivate: number = 0
  public cycleTS0: number = 0
  public cycleTS1: number = 0
  public shiftTS0: number = 0
  public shiftTS1: number = 0

  constructor(data: DC | null = null) {
    if (data) {
      this.enabled = data.enabled
      this.assignActivate = data.assignActivate
      this.cycleTS0 = data.cycleTS0
      this.cycleTS1 = data.cycleTS1
      this.shiftTS0 = data.shiftTS0
      this.shiftTS1 = data.shiftTS1
    }
  }
}
