
<div id="app">
  <nav class="navigation">
    <ul>
      <li>
        <v-popover class="menu" :offset="[90, 15]" ref="popover">
          <template v-slot:default>
            <button class="icon darker large menu-button">
              <v-icon name="settings"></v-icon>
            </button>
          </template>
          <template v-slot:popover>
            <ul>
              <li>
                <div class="logo">
                  <v-logo href="/"></v-logo>
                </div>
              </li>
              <li>
                <button class="anchor" @click="$modal.show('connection')">
                  <v-icon name="server"></v-icon>
                  {{ $t('Connection') }}
                </button>
              </li>
              <li>
                <button class="anchor" @click="$modal.show('devices')">
                  <v-icon name="plus"></v-icon>
                  {{ $t('Add Devices') }}
                </button>
              </li>
              <li>
                <button class="anchor" @click="createNewConfiguration">
                  <v-icon name="plus"></v-icon>
                  {{ $t('New Configuration') }}
                </button>
              </li>
              <li>
                <v-file class="icon anchor" accept="application/xml" @change="loadConfiguration">
                  <v-icon name="upload-cloud"></v-icon>
                  {{ $t('Load Configuration') }}
                </v-file>
              </li>
              <li>
                <button class="anchor" @click="downloadConfiguration" :disabled="!hasDomains">
                  <v-icon name="download-cloud"></v-icon>
                  {{ $t('Download Configuration') }}
                </button>
              </li>
              <li>
                <button class="anchor" @click="$modal.show('deploy')">
                  <v-icon name="code"></v-icon>
                  {{ $t('Preview & Deploy') }}
                </button>
              </li>
              <li>
                <button class="anchor" @click="$modal.show('settings')">
                  <v-icon name="settings"></v-icon>
                  {{ $t('Settings') }}
                </button>
              </li>
              <li>
                <v-toggle-darkmode darkmode></v-toggle-darkmode>
              </li>
              <li v-if="isDebug">
                <router-link to="/testing">
                  <v-icon name="aperture"></v-icon>
                  {{ $t('Tests') }}
                </router-link>
              </li>
              <li>
                <div class="version">
                  <strong>{{ $t('Version') }}</strong>
                  <br />
                  {{ version }}
                </div>
              </li>
            </ul>
          </template>
        </v-popover>
      </li>
    </ul>

    <!-- <v-input class="blank project-name" placeholder="project name" darkmode></v-input> -->

    <ul class="actions">
      <li>
        <v-promise-button class="darker" @click="fetchConfiguration" :disabled="!app.canScan">
          <template slot="loading">
            <v-loader></v-loader>
            {{ $t('Fetching') }}
          </template>
          <template slot="success">
            <v-icon name="check"></v-icon>
            {{ $t('Completed') }}
          </template>
          <template slot="error">
            <v-icon name="x"></v-icon>
            {{ $t('Failed') }}
          </template>
          <v-icon name="upload"></v-icon>
          {{ $t('Fetch') }}
        </v-promise-button>
      </li>
      <li>
        <v-deploy :disabled="!app.canScan"></v-deploy>
      </li>
      <li
        class="connection-indicator"
        :class="{ 'is-connected': app.isConnected }"
        @click="$modal.show('connection')"
      >
        <v-loader v-if="app.isConnecting"></v-loader>
        <span v-else></span>
        <v-icon name="lock" v-if="app.hasSecureConnection"></v-icon>
        <v-icon name="unlock" v-else></v-icon>
      </li>
    </ul>
  </nav>

  <router-view></router-view>
  <v-flits></v-flits>
  <v-dialog></v-dialog>
  <v-modal-deploy></v-modal-deploy>
  <v-modal-devices></v-modal-devices>
  <v-modal-parameters></v-modal-parameters>
  <v-modal-connection></v-modal-connection>
  <v-modal-settings></v-modal-settings>
</div>
