import { Component, Watch } from 'vue-property-decorator'
import Base from '/@/shared/classes/base'
import Motorcortex from '/@/shared/motorcortex'
import { TreeNode } from '@vectioneer/motorcortex-js/src/tree_node'
import FuzzySet from 'fuzzyset'
import { ConnectionParameters } from '/@/shared/ConnectionParameters'
import { sessionStatus } from '/@/shared/entities/enums/SessionState'

@Component({
  components: {
    'v-create': () => import('/@/modals/parameters/create/index.vue'),
  },
})
export default class Parameters extends Base {
  private searchQuery = ''
  private app = this.loadAppModule()
  private tree: any = null
  private linkableProperty: any = null
  private selectedNode: any = null
  private connection: Motorcortex | null = null

  @Watch('parameters', { immediate: true, deep: true })
  private onParametersChanged(parameters: ConnectionParameters) {
    if (!this.parameters.canConnect) {
      return
    }

    if (!this.connection) {
      this.createConnection()
    } else {
      this.connection.updateConnectionParameters(parameters)
    }
  }

  private connect() {
    this.connection?.connect()
  }

  private async createConnection() {
    try {
      this.connection = new Motorcortex(this.parameters, {
        on: {
          connectionFailed: (event) => {
            const connection = event.target as Motorcortex
            setTimeout(() => {
              connection.connect()
            }, 2500)
          },
          connected: (event) => {
            this.tree = (event.target as Motorcortex).getTree()
            this.app.setConnectionStatus(event.type as sessionStatus)
          },
        },
      })

      this.connection.on(['connecting', 'disconnected', 'connectionLost', 'disconnected'], (event) => {
        this.tree = null
        this.app.setConnectionStatus(event.type as sessionStatus)
      })

      await this.connection.connect()
    } catch (error) {
      if (import.meta.env.VITE_DEBUG) {
        // eslint-disable-next-line
        console.error(error)
      }

      this.$flits.error(error.text)
      this.app.setConnectionStatus('error')
    }
  }

  private get hasSearchQuery(): boolean {
    return this.searchQuery.length !== 0
  }

  private get parameters() {
    return this.app.connectionParameters
  }

  private get canConnect() {
    return this.parameters.canConnect
  }

  private get hasTree() {
    return this.tree !== null
  }

  private get nodes(): any {
    return this.hasSearchQuery ? this.search({ name: this.searchQuery }) : this.tree.children
  }

  private clearNode(name: string) {
    this.selectedNode = null
  }

  private addLinkedParameter(data: any) {
    this.ethercat.addLinkedParameter({
      item: this.linkableProperty,
      ...data,
    })

    this.$modal.hide('parameter-edit')
    this.$modal.hide('parameters')
  }

  private selectNode(event: { node?: any; info: any; channel?: any }) {
    this.selectedNode = {
      ...event.info,
      gain: 1,
      divide: 1,
      gainOffset: 0,
      channel: event.channel ?? null,
    }

    this.$nextTick(() => this.$modal.show('parameter-edit'))
  }

  private search(term: any) {
    const node = new TreeNode('root')
    this.buildTree(this.tree, term, node)
    this.mergeDoubleNodes(node)

    return node.children.length > 0 //
      ? [node]
      : []
  }

  private mergeDoubleNodes(node: any) {
    const result: any = []

    node.children.forEach((el: any) => {
      const existingIndex = result.findIndex((r: any) => r.name === el.name)

      if (existingIndex < 0) {
        result.push(el)
      } else {
        const children = el.children
        el = result[existingIndex]
        el.children = el.children.concat(children)
      }

      this.mergeDoubleNodes(el)
    })

    node.children = result
  }

  private buildTree(tree: any, term: any, node: any) {
    tree.children.forEach((el: any) => {
      const pattern = RegExp(term.name, 'i')
      let found = el.name.match(pattern)

      if (!found) {
        const fz = FuzzySet()

        let haystack = el.name
        const minRequiredScore = 0.75

        if (term.name.indexOf('/') !== -1) {
          haystack = el.info.path
        }

        fz.add(haystack)

        const result = fz.get(term.name)
        let score = 0

        if (result && Array.isArray(result)) {
          ;[score] = result[0]
        }

        found = score > minRequiredScore
      }

      if (found) {
        let copy = el

        while (copy.type > 0) {
          const parent = copy
            .getParent() //
            .copy()

          if (parent.getParent().type === 0) {
            break // break on root
          }

          parent.children = [copy]
          copy = parent
        }

        el = copy

        node.children.push(el)
      } else {
        this.buildTree(el, term, node)
      }
    })
  }

  private setLinkableProperty(prop: any) {
    this.linkableProperty = prop
  }

  private clear() {
    this.linkableProperty = null
  }
}
