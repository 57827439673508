
<v-modal name="settings">
  <div class="settings">
    <div class="two-column-card">
      <div>
        <h2>{{ $t('Connection Settings') }}</h2>
        <p>{{ $t('This section configures your connection with the ethercat services running on your server.') }}</p>
      </div>

      <v-panel>
        <template #default>
          <form>
            <fieldset>
              <v-input type="number" v-model.number="ethercatSettings.port" :placeholder="$t('Services Port')">
                {{ $t('Port') }}
              </v-input>
              <v-input
                icon="clock"
                type="number"
                v-model.number="ethercatSettings.timeout"
                :placeholder="$t('Services Timeout')"
              >
                {{ $t('Timeout') }}
              </v-input>
            </fieldset>
          </form>
        </template>
      </v-panel>
    </div>

    <div class="two-column-card">
      <div>
        <h2>{{ $t('Endpoint Settings') }}</h2>
        <p>{{ $t('Configure the endpoints of your ethercat service in this section.') }}</p>
      </div>

      <v-panel>
        <template #default>
          <form>
            <fieldset>
              <template v-for="(endpoint, key) in ethercatSettings.endpoints">
                <v-input
                  icon="link-2"
                  :value="ethercatSettings.endpoints[key]"
                  :key="key"
                  @input="updateEndpoints($event, key)"
                >
                  {{ changeCamelCaseToSpaces(key) }}
                </v-input>
              </template>
            </fieldset>
          </form>
        </template>
      </v-panel>
    </div>

    <div class="column">
      <div></div>
      <ul class="actions flex flex-end">
        <li>
          <button class="secondary" @click="reset">{{ $t('Reset') }}</button>
        </li>
        <li>
          <button class="primary" @click="$modal.hide('settings')">{{ $t('Confirm') }}</button>
        </li>
      </ul>
    </div>
  </div>
</v-modal>
