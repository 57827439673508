import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = import.meta.globEager('./locales/*.json', { assert: { type: 'raw' } })
  const messages = {}

  for (const path in locales) {
    const matched = path.match(/([A-Za-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = JSON.parse(locales[path])
    }
  }

  return messages
}

export default new VueI18n({
  locale: import.meta.env.VITE_I18N_LOCALE ?? 'en',
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
})
