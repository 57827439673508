import { removeNumbersFromString } from '../../utils'

export enum TypeSignature {
  NOT_SET,
  BIT,
  BOOL,
  INT,
  UINT,
  FLOAT,
  DOUBLE,
  STRING,
}

export function listKeys() {
  return Object.keys(TypeSignature)
    .filter((key) => !(parseInt(key) >= 0))
    .filter((key) => typeof TypeSignature[key as any] !== 'function')
}

export function findByString(type: string): TypeSignature {
  let typeString = removeNumbersFromString(type.toUpperCase())

  if (['SINT', 'DINT'].includes(typeString)) {
    typeString = 'INT'
  }

  if (['USINT', 'UDINT'].includes(typeString)) {
    typeString = 'UINT'
  }

  if (!listKeys().includes(typeString)) {
    throw new Error(`Unknown signature: ${type}`)
  }

  return TypeSignature[typeString as keyof typeof TypeSignature]
}
