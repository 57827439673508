import Excludable from './Excludable'

export default class Hideable extends Excludable {
  public visible: boolean = true

  public constructor(...args: any[]) {
    super(args)
    this.processData(args)
  }

  public get isVisible() {
    return this.visible && this.isIncluded
  }

  public processData(data: any) {
    super.processData(data)

    if (Array.isArray(data)) {
      data = data[0]
    }

    if (data.visible !== undefined) {
      this.visible = data.visible
    }
  }
}
