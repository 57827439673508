import ErrorStackParser from 'error-stack-parser'
import debounce from 'lodash.debounce'

declare let console: any
const portalUrl = import.meta.env.VITE_PORTAL_URL

if (!portalUrl) {
  console.error('VITE_PORTAL_URL is not defined in .env file')
}

const url = `${portalUrl}errors/js`
const consoleMinLevel = process.env.NODE_ENV === 'production' ? 300 : 0
const serverMinLevel = 300
const logLevels = {
  DEBUG: 100,
  INFO: 200,
  NOTICE: 250,
  WARNING: 300,
  ERROR: 400,
  CRITICAL: 500,
  ALERT: 550,
  EMERGENCY: 600,
}

interface StackElement {
  column: number
  line: number
  file: string
}

interface Context {
  native?: boolean
  stack?: StackElement[]
  ua?: string
  payload?: any
  url?: string
}

export default class Logger {
  [key: string]: Function | any[]

  private logs: any[] = []

  constructor() {
    for (const name in logLevels) {
      this[name.toLowerCase()] = function () {
        const logMethods = this.addLogRecord as any
        logMethods.apply(this, [name, ...arguments])
      }
    }

    this.attachEventListeners()
  }

  attachEventListeners() {
    window.addEventListener('beforeunload', () => {
      if (this.logs.length && navigator.sendBeacon) {
        return this.sendBeacon()
      }
    })

    window.addEventListener('error', (event) => {
      this.addErrorRecord(event.error)
    })

    // 'capture' listener, listen to missing images etc
    window.addEventListener(
      'error',
      (event) => {
        if (event.target === window) {
          // these are handled by the previous listener
          return
        }

        const target = event.target

        if (target instanceof Element) {
          const tagName = target.tagName

          if (tagName === 'IMG') {
            // missing image
            return this.addLogRecord('WRNING', `Missing image: ${(target as HTMLImageElement).src}`)
          }

          if (tagName === 'image') {
            if (!target.getAttribute('xlink:href')?.length) {
              return
            }
          }

          return this.addLogRecord('WARNING', `Missing asset in ${tagName}`, {
            payload: {
              html: target.outerHTML,
            },
          })
        }
      },
      true,
    )

    window.addEventListener('unhandledrejection', () => {
      // unhandled promise rejection
    })

    window.addEventListener('messageerror', () => {
      // failed to deserialize worker message
    })
  }

  getLevelFromName(name: string) {
    return (logLevels as any)[name.toUpperCase()]
  }

  addErrorRecord(error: Error) {
    let elements

    try {
      elements = ErrorStackParser.parse(error)
    } catch (e) {
      // eslint-disable-next-line
      console.error('Could not parse', error)
      return
    }

    const stack: StackElement[] = elements.map((item) => {
      return {
        column: item.columnNumber,
        line: item.lineNumber,
        file: item.fileName,
      } as StackElement
    })

    const context: Context = {
      native: true,
      url: location.href,
      stack,
    }

    /* TODO: Catch fetch errors */
    this.addLogRecord('ERROR', `${error.name} ${error.message}`, context)
  }

  addLogRecord(levelName: string, message: string, context: Context = {}) {
    if (typeof message === 'undefined' || !message.length) {
      return
    }

    // add UA to context
    context.ua = window.navigator.userAgent

    const log = {
      level: levelName,
      message,
      context,
    }

    this.logToConsole(log)

    if (this.getLevelFromName(log.level) < serverMinLevel) {
      return
    }

    this.logs.push(log)
    this.sendToServer()
  }

  /* tslint:disable */
  logToConsole(log: any) {
    if (this.getLevelFromName(log.level) < consoleMinLevel) {
      return
    }

    let methodName = log.level.toLowerCase()
    if (methodName === 'debug') {
      methodName = 'log'
    }

    if (typeof console === 'undefined') {
      return false
    } else if (console[methodName] !== undefined) {
      return console[methodName](log.message)
    } else if (console.log !== undefined) {
      return console.log(log.message)
    }
  }

  collectData() {
    return { logs: this.logs }
  }

  sendToServer = debounce(() => {
    if (navigator.hasOwnProperty('sendBeacon')) {
      console.log('send beacon')
      return this.sendBeacon()
    }

    const xmlhttp = new XMLHttpRequest()
    xmlhttp.open('POST', url)
    xmlhttp.setRequestHeader('Content-Type', 'application/json')
    xmlhttp.send(JSON.stringify(this.collectData()))

    this.logs = []
  }, 5000)
  /* tslint:enable */

  sendBeacon() {
    const fd = new FormData()
    fd.append('logs', JSON.stringify(this.logs))
    fd.append('beacon', '1')

    navigator.sendBeacon(url, fd)

    this.logs = []
  }
}
