
<v-modal name="deploy" @modal-show="generateConfiguration">
  <v-panel class="rounded deploy">
    <template v-slot:header>
      <h3>
        <v-icon name="eye"></v-icon>
        {{ $t('Preview Configuration') }}
      </h3>
    </template>
    <template v-slot:default>
      <v-deploy :disabled="!connection.canScan" :show-restart="true"></v-deploy>
      <pre class="code">{{ configuration }}</pre>
    </template>
  </v-panel>
</v-modal>
