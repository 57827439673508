
<v-modal name="devices" @callback="modalCallback" @modal-hide="clear">
  <section class="devices-edit">
    <header>
      <h4>
        <v-icon name="plus"></v-icon>
        {{ $t('Add Devices') }}
      </h4>
    </header>

    <v-tabs>
      <v-tab :name="$t('From Scan')">
        <v-device-list :devices="devices" @add-devices="addDevices">
          <v-promise-button class="dark rounded small icon" @click="scan" :disabled="!connection.canScan">
            <template slot="loading">
              <v-loader></v-loader>
              {{ $t('Scanning') }}
            </template>
            <template slot="success">
              <v-icon name="check"></v-icon>
              {{ $t('Completed') }}
            </template>
            <template slot="error">
              <v-icon name="x"></v-icon>
              {{ $t('Failed') }}
            </template>
            <v-icon name="crosshair"></v-icon>
            {{ $t('Scan Bus') }}
          </v-promise-button>
        </v-device-list>
      </v-tab>

      <v-tab :name="$t('From Configuration')">
        <v-device-list :devices="configDevices" @add-devices="addDevices">
          <v-file class="secondary rounded small icon" accept="text/xml" @change.native="loadConfigFile">
            <v-icon name="upload"></v-icon>
            {{ $t('Upload File') }}
          </v-file>

          <v-promise-button class="dark rounded small icon" @click="scanConfigFile" :disabled="!connection.canScan">
            <template slot="loading">
              <v-loader></v-loader>
              {{ $t('Scanning') }}
            </template>
            <template slot="success">
              <v-icon name="check"></v-icon>
              {{ $t('Completed') }}
            </template>
            <template slot="error">
              <v-icon name="x"></v-icon>
              {{ $t('Failed') }}
            </template>
            <v-icon name="crosshair"></v-icon>
            {{ $t('Scan Config') }}
          </v-promise-button>
        </v-device-list>
      </v-tab>

      <v-tab :name="$t('From Description Files')">
        <v-device-list
          :devices="ESIDevices"
          :is-editable="true"
          @edit-device="editDevice"
          @add-devices="addDevices($event, true)"
          @remove-devices="removeDevices"
        >
          <v-file class="dark rounded small icon" accept="text/xml" @change.native="loadFromEsiFiles" multiple>
            <v-icon name="upload"></v-icon>
            {{ $t('Upload Files') }}

            <v-loader v-if="isLoading"></v-loader>
          </v-file>
        </v-device-list>
        <v-modal name="select-devices" @modal-hide="clearESIDevices">
          <v-devices-select :devices="ESIDevices" @select-devices="addEsiDevices"></v-devices-select>
        </v-modal>
      </v-tab>

      <v-tab :name="$t('Manually')">
        <v-device-list
          :devices="manualDevices"
          @edit-device="editDevice"
          @add-devices="addDevices($event)"
          :is-editable="true"
          @remove-devices="removeDevices"
        >
          <button class="success small rounded" @click="$modal.show('create-device')">
            <v-icon name="plus"></v-icon>
            {{ $t('Device') }}
          </button>
        </v-device-list>
      </v-tab>
    </v-tabs>

    <v-modal name="create-device">
      <v-device-edit :device="selectedDevice" @create-device="createDevice"></v-device-edit>
    </v-modal>
  </section>
</v-modal>
