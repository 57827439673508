import { uuid } from '/@/shared/utils'
import Excludable from './shared/Excludable'

interface ParameterData {
  name: string
  path: string
  type: string
  icon: string
  numberOfElements: number
}

export class LinkedParameter extends Excludable {
  public readonly data: ParameterData
  public readonly uuid = uuid()

  public channel: number = 0
  public sim: boolean = false
  public gain = 1
  public divide = 1
  public gainOffset = 0
  public invert = false

  public get havePropertiesChanged() {
    return this.gain !== 1 || this.gainOffset !== 0 || this.invert !== false || this.sim !== false || this.divide !== 1
  }

  constructor(
    data: ParameterData,
    channel: number,
    sim: boolean,
    gain?: number,
    divide?: number,
    gainOffset?: number,
    invert?: boolean,
  ) {
    super(data)

    this.data = data
    this.channel = channel
    this.sim = sim

    if (gain) {
      this.gain = gain
    }

    if (divide) {
      this.divide = divide
    }

    if (gainOffset) {
      this.gainOffset = gainOffset
    }

    if (invert) {
      this.invert = invert
    }
  }
}
