import { Component, Prop } from 'vue-property-decorator'
import Base from '/@/shared/classes/base'
import { EthercatDevice } from '/@/shared/entities/EthercatDevice'
import ConfigFileAdapter from '/@/shared/adapters/device-list/configuration/FileAdapter'
import ConfigScanAdapter from '/@/shared/adapters/device-list/configuration/ScanAdapter'
import DescriptionFileAdapter from '/@/shared/adapters/device-description/FileAdapter'
import ScanAdapter from '/@/shared/adapters/device-list/ScanAdapter'
import { EthercatDomain } from '/@/shared/entities/EthercatDomain'
import { fetchDescriptions, findDeviceByType } from '/@/shared/worker-helpers'

@Component({
  components: {
    'v-device-list': () => import('./device-list/index.vue'),
    'v-device-edit': () => import('./edit/index.vue'),
    'v-devices-select': () => import('./select/index.vue'),
  },
})
export default class Devices extends Base {
  private app = this.loadAppModule()

  private isLoading = false
  private devices: EthercatDevice[] = []
  private configDevices: EthercatDevice[] = []
  private ESIDevices: EthercatDevice[] = []
  private manualDevices: EthercatDevice[] = []

  private selectedDevice: EthercatDevice | null = null
  private domain: EthercatDomain | null = null
  private afterDevice: EthercatDevice | null = null

  private get connection() {
    return this.app.connectionParameters
  }

  private get hasHost(): boolean {
    return this.connection.host.length > 0
  }

  private modalCallback({ domain, device }: { domain?: EthercatDomain; device?: EthercatDevice }) {
    if (domain) {
      this.domain = domain
    }

    if (device) {
      this.afterDevice = device
    }
  }

  private async scan() {
    this.isLoading = true

    try {
      this.devices = await new ScanAdapter().process(this.connection.host)
    } catch (error) {
      this.$flits.error(error)
      throw error
    } finally {
      this.isLoading = false
    }
  }

  private async loadConfigFile(event: InputEvent) {
    const target = event.target as HTMLInputElement
    const file = (target.files as FileList).item(0)

    if (!file) {
      return
    }

    try {
      this.configDevices = await new ConfigFileAdapter().process(file)
    } catch (error) {
      this.$flits.error(error)
      throw error
    } finally {
      target.value = ''
    }
  }

  private async scanConfigFile() {
    this.isLoading = true

    try {
      this.configDevices = await new ConfigScanAdapter().process(this.connection)
    } catch (error) {
      this.$flits.error(error)
      throw error
    } finally {
      this.isLoading = false
    }
  }

  private async loadFromEsiFiles(event: InputEvent) {
    const target = event.target as HTMLInputElement
    const files = target.files as FileList
    const adapter = new DescriptionFileAdapter()

    this.isLoading = true

    try {
      const promises = Array.from(files).map(async (file) => {
        return await adapter.process(file)
      })

      this.ESIDevices = (await Promise.all(promises)).flat(1).sort((a, b) => a.id - b.id)
      this.$modal.show('select-devices')
    } catch (error) {
      this.$flits.error(error)
      throw error
    } finally {
      target.value = ''
      this.isLoading = false
    }
  }

  private addEsiDevices(devices: EthercatDevice[]) {
    this.$modal.hide('select-devices')
    this.ESIDevices = devices
  }

  private editDevice(device: EthercatDevice) {
    this.selectedDevice = device
    this.$modal.show('create-device')
  }

  private addDevices(devices: EthercatDevice[], autogeneratePosition: boolean = false) {
    this.ethercat.addDevices({ devices, domain: this.domain, after: this.afterDevice, autogeneratePosition })
    this.fetchDescriptions(devices)
    this.$modal.hide('devices')
  }

  private createDevice(device: EthercatDevice) {
    this.manualDevices.push(device)
  }

  private removeDevices(devices: EthercatDevice[]) {
    this.ESIDevices = this.ESIDevices.filter((device) => !devices.includes(device))
    this.manualDevices = this.manualDevices.filter((device) => !devices.includes(device))
  }

  private clearESIDevices() {
    this.ESIDevices = []
  }

  private clear() {
    this.devices = []
    this.ESIDevices = []
    this.manualDevices = []
    this.configDevices = []
    this.domain = null
    this.afterDevice = null
  }

  private created() {
    this.loadAppModule()
  }

  private async fetchDescriptions(devices: EthercatDevice[]) {
    this.$flits.info('Fetching descriptions')
    let descriptions = await fetchDescriptions(devices, this.app.connectionParameters, this.app.ethercatSettings)

    descriptions = descriptions
      .map((description: any) => {
        description.device = findDeviceByType(description.device, this.ethercat.devices)
        return description
      })
      .filter((description: any) => description.device !== undefined)

    descriptions.forEach((description: any) => {
      this.ethercat.applyDeviceDescription(description)
    })

    this.$flits.success('Descriptions correctly loaded')
  }
}
