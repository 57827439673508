
<v-modal name="parameters" @callback="setLinkableProperty" @modal-hide="clear" :keep-alive="true">
  <v-panel class="v-parameters">
    <template v-slot:header>
      <div class="flex flex-center space-between">
        <h4>
          <v-icon name="sliders"></v-icon>
          {{ $t('Parameters') }}
        </h4>

        <ul class="actions">
          <li>
            <button class="small secondary rounded" @click="$modal.show('parameter-edit')">
              <v-icon name="plus"></v-icon>
              {{ $t('Add Manually') }}
            </button>
          </li>
          <li>
            <button class="small secondary icon rounded" @click="connect" v-if="app.isConnectionInErrorState">
              <v-icon name="refresh-cw"></v-icon>
              {{ $t('Retry connecting') }}
            </button>
          </li>
        </ul>
      </div>
    </template>
    <template v-slot:default>
      <template v-if="canConnect && hasTree">
        <div class="search">
          <fieldset>
            <v-input
              icon="search"
              :placeholder="$t('Search')"
              class="small"
              icon-position="right"
              v-model="searchQuery"
              :delay="500"
            ></v-input>
          </fieldset>
        </div>

        <v-tree
          :nodes="nodes"
          :is-searching="hasSearchQuery"
          :emit-on-click="true"
          @select-node="selectNode"
          v-if="hasTree"
        ></v-tree>
      </template>
      <template v-else>
        <div class="no-connection">
          <v-icon name="wifi-off"></v-icon>
          <h4>{{ $t('Connection Failed') }}</h4>
          <h6>{{ $t('Check your connection details') }}</h6>

          <button class="primary" @click="$modal.show('connection')">{{ $t('Connection Details') }}</button>
          <small v-if="app.isConnecting">
            <span class="badge primary">
              <v-loader></v-loader>
              {{ $t('attempting to reconnect') }}
            </span>
          </small>
        </div>
      </template>
    </template>
  </v-panel>
  <v-modal name="parameter-edit" @modal-hide="clearNode">
    <v-create @create-parameter="addLinkedParameter" :data="selectedNode"></v-create>
  </v-modal>
</v-modal>
