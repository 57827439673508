import { spawn, Thread } from 'threads'
import { ConnectionParameters } from './ConnectionParameters'
import EthercatSettings from './EthercatSettings'
import { EthercatDevice } from '/@/shared/entities/EthercatDevice'
import { convertHexToNumber } from '/@/shared/utils'
import DescriptionWorker from '/@/shared/workers/processDescriptions.ts?worker'
import { areTheSameHexValue } from './utils'

export async function fetchDescriptions(
  devices: EthercatDevice[],
  connection: ConnectionParameters,
  settings: EthercatSettings,
) {
  if (!devices.length) {
    return
  }

  const descriptionWorker = await spawn(new DescriptionWorker())
  await descriptionWorker.setup({ host: connection.host, settings })

  const descriptions = await descriptionWorker.process(devices)
  await Thread.terminate(descriptionWorker)

  return descriptions
}

export function findDeviceByType(type: { revisionNo: string; productCode: string }, devices: EthercatDevice[]) {
  return devices.find((device) => {
    return (
      areTheSameHexValue(device.type.revisionNo, type.revisionNo) &&
      areTheSameHexValue(device.type.productCode, type.productCode)
    )
  })
}
