import { TypeSignature, findByString } from './enums/TypeSignature'

export class ObjectType {
  public signature: TypeSignature
  public text: string
  public changeLengthAllowed: boolean
  public defaultLength: number
  public indexMultiplier: number

  private constructor(
    signature: TypeSignature,
    text: string,
    changeLengthAllowed: boolean,
    defaultLength: number,
    indexMultiplier: number = 1,
  ) {
    this.signature = signature
    this.text = text
    this.changeLengthAllowed = changeLengthAllowed
    this.defaultLength = defaultLength
    this.indexMultiplier = indexMultiplier
  }

  public static ALL: ReadonlyArray<ObjectType> = [
    new this(TypeSignature.NOT_SET, 'Not set', true, 1),
    new this(TypeSignature.BIT, 'Bit', true, 1),
    new this(TypeSignature.BOOL, 'Bool', false, 1),
    new this(TypeSignature.INT, 'Int', true, 8),
    new this(TypeSignature.UINT, 'UInt', true, 8),
    new this(TypeSignature.FLOAT, 'Float', false, 32),
    new this(TypeSignature.DOUBLE, 'Double', false, 64),
    new this(TypeSignature.STRING, 'String', true, 1, 8),
  ]

  public static INTEGER_INCREMENTS: ReadonlyArray<number> = [8, 16, 24, 32, 40, 48, 56, 64]

  public static getTypeBySignature(signature: TypeSignature): ObjectType {
    return this.ALL.find((type) => type.signature === signature) || this.ALL[0]
  }

  public static getIndexMultiplierBySignature(signature: TypeSignature): number {
    return this.getTypeBySignature(signature).indexMultiplier
  }

  public static getSignatureByTypeString(type: string): TypeSignature {
    return findByString(type)
  }
}
