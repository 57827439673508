import { Entry } from '/@/shared/entities/Entry'
import { PDO } from '/@/shared/entities/PDO'
import { SDO } from '/@/shared/entities/SDO'
import { extractIfExists } from '../utils'
import { Mapping } from '../entities/Mapping'
import { XMLBuilder } from 'xmlbuilder2/lib/interfaces'
import { EthercatDevice } from '../entities/EthercatDevice'

const formatIndex = (index: string | number) => {
  return `#x${index}`
}

const createLinkElements = (
  el: XMLBuilder,
  model: Entry | PDO | Mapping | EthercatDevice,
  tagname: string = 'Link',
) => {
  model.linkedParameters.forEach((parameter) =>
    el
      .ele(tagname, {
        Index: parameter.channel,
        Sim: parameter.sim,
        ...(parameter.gain !== 1 ? { Gain: parameter.gain } : {}),
        ...(parameter.divide !== 1 ? { Divide: parameter.divide } : {}),
        ...(parameter.gainOffset !== 0 ? { GainOffset: parameter.gainOffset } : {}),
        ...(parameter.invert ? { Invert: parameter.invert } : {}),
        ...addIncludedProperty(parameter),
      })
      .txt(parameter.data.path),
  )
}

const addVisiblePoperty = (model: any) => {
  return {
    ...(!model.isVisible ? { Visible: false } : {}),
  }
}

const addIncludedProperty = (model: any) => {
  return {
    ...(!model.isIncluded ? { Included: false } : {}),
  }
}

const createEntryElements = (el: XMLBuilder, model: SDO | PDO) => {
  model.entries.forEach((entry) => {
    const entryEl = el.ele('Entry', {
      ...extractIfExists(entry, 'name'),
      ...extractIfExists(entry, 'group'),
      ...addVisiblePoperty(entry),
      ...addIncludedProperty(entry),
    })

    createLinkElements(entryEl, entry)

    entryEl.ele('Index').txt(formatIndex(entry.index))
    entryEl.ele('SubIndex').txt(entry.subIndex.toString())
    entryEl.ele('BitLen').txt(entry.getBitLength().toString())
    entryEl.ele('DataType').txt(entry.getTypeString())

    entry?.maps.forEach((map) => {
      const mapEl = entryEl.ele('Map', {
        ...extractIfExists(map, 'name'),
        ...extractIfExists(map, 'group'),
        ...addVisiblePoperty(map),
        ...addIncludedProperty(map),
      })

      const [Index, SubIndex] = map.offset.split(':')

      createLinkElements(mapEl, map)

      mapEl.ele('Index').txt(formatIndex(Index))
      mapEl.ele('SubIndex').txt(SubIndex.toString())
      mapEl.ele('BitLen').txt(map.bitLength.toString())
      mapEl.ele('DataType').txt(map.getTypeString())
    })

    if (entry.hasGap) {
      const gap = el.ele('Entry')
      gap.ele('Index').txt('#x0000')
      gap.ele('BitLen').txt(entry.gapLength.toString())
      gap.ele('Name').txt(entry.gapName)
    }
  })
}

export { formatIndex, createLinkElements, createEntryElements, addVisiblePoperty, addIncludedProperty }
