
<v-modal name="connection">
  <v-panel class="connection rounded">
    <template v-slot:header>
      <h3>
        <v-icon name="server"></v-icon>
        {{ $t('Connection') }}
      </h3>
    </template>

    <template v-slot:default>
      <form class="connection">
        <fieldset>
          <v-input v-model="connection.source" :lazy="true" :placeholder="$t('IP address or domain')">
            <v-icon name="server"></v-icon>
            {{ $t('Host') }}
            <template v-slot:group-element>
              <v-switch label-on="TLS" label-off="TLS" v-model="connection.secured"></v-switch>
            </template>
          </v-input>

          <div class="inline">
            <v-input :placeholder="$t('Request Port')" v-model="connection.requestPort" :lazy="true">
              {{ $t('Request') }}
            </v-input>
            <v-input :placeholder="$t('Receive Port')" v-model="connection.receivePort" :lazy="true">
              {{ $t('Receive') }}
            </v-input>
          </div>

          <div class="inline">
            <v-input :placeholder="$t('Username')" v-model="connection.login" :lazy="true" autocomplete="username">
              <v-icon name="user"></v-icon>
              {{ $t('Username') }}
            </v-input>
            <v-input
              type="password"
              v-model="connection.password"
              :lazy="true"
              :placeholder="$t('Password')"
              autocomplete="current-password"
            >
              <v-icon name="key"></v-icon>
              {{ $t('Password') }}
            </v-input>
          </div>
        </fieldset>
      </form>
    </template>

    <template v-slot:footer>
      <button class="anchor" @click="$modal.hide('connection')">{{ $t('Confirm') }}</button>
    </template>
  </v-panel>
</v-modal>
