import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { ConnectionParameters } from '/@/shared/ConnectionParameters'
import EthercatSettings from '/@/shared/EthercatSettings'
import EthercatConnector from '/@/shared/EthercatConnector'
import { sessionStatus } from '/@/shared/entities/enums/SessionState'

@Module({ name: 'app', namespaced: true })
class App extends VuexModule {
  public connectionParameters: ConnectionParameters = new ConnectionParameters()
  public ethercatSettings: EthercatSettings = new EthercatSettings()
  public connectionStatus: sessionStatus | 'error' | null = null

  @Mutation
  public updateConnectionProperty(updates: { [key: string]: any }) {
    Object.assign(this.connectionParameters, updates)
  }

  @Mutation
  public updateEthercatSettingsProperty(updates: { [key: string]: any }) {
    Object.assign(this.ethercatSettings, updates)
  }

  @Mutation
  public setConnectionStatus(status: sessionStatus | 'error') {
    this.connectionStatus = status
  }

  public get canConnect(): boolean {
    return this.connectionParameters.canConnect
  }

  public get canScan(): boolean {
    return this.connectionParameters.canScan
  }

  public get connectionHost() {
    return this.connectionParameters.host
  }

  public get ethercatConnector() {
    return new EthercatConnector(this.connectionHost, {
      ...this.ethercatSettings,
    })
  }

  public get hasSecureConnection() {
    return this.connectionParameters.secured
  }

  public get isConnectionInErrorState() {
    return this.connectionStatus === 'error'
  }

  public get isConnected() {
    return this.connectionStatus === 'connected'
  }

  public get isDisconnected() {
    return this.connectionStatus === 'disconnected'
  }

  public get hasConnectionFailed() {
    return this.connectionStatus === 'connectionFailed'
  }

  public get isConnecting() {
    return !this.isConnected && !this.isDisconnected && !this.hasConnectionFailed && !this.isConnectionInErrorState
  }
}

export default App
